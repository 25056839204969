// You can customize Simplelightbox with the following variables:

$sl-font-family: Arial, Baskerville, monospace !default;
$sl-overlay-background: #fff !default;
$sl-navigation-color: #fff !default;
$sl-caption-color: #fff !default;
$sl-caption-background: rgba(0, 0, 0, 0.8) !default;

$sl-counter-fontsize: 1rem !default;
$sl-download-fontsize: 1rem !default;
$sl-caption-fontsize: 1rem !default;
$sl-close-fontsize: 3rem !default;

$sl-breakpoint-medium: 35.5em !default; // 568px, when 1em == 16px
$sl-breakpoint-large: 50em !default; // 800px, when 1em == 16px

$sl-arrow-fontsize-small: 2rem !default;
$sl-arrow-fontsize-medium: 3rem !default;
$sl-arrow-fontsize-large: 3rem !default;
$sl-img-border-small: 0 none !default;
$sl-img-border-medium: 0 none !default;
$sl-img-border-large: 0 none !default;
$sl-iframe-border-small: 0 none !default;
$sl-iframe-border-medium: 0 none !default;
$sl-iframe-border-large: 0 none !default;

$add-vendor-prefixes: true !default;

body.hidden-scroll {
  overflow: hidden;
}

.sl-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: black;
  display: none;
  z-index: 999999999;
}

.sl-wrapper {
  z-index: 99999999999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: fixed;

  * {
    box-sizing: border-box;
  }
  button {
    border: 0 none;
    background: transparent;
    font-size: 28px;
    padding: 0;
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  }

  .sl-close {
    display: none;
    position: fixed;
    right: 30px;
    top: 30px;
    z-index: 10060;
    margin-top: -14px;
    margin-right: -14px;
    height: 44px;
    width: 44px;
    line-height: 44px;
    font-family: $sl-font-family;
    color: $sl-navigation-color;
    font-size: $sl-close-fontsize;

    &:focus {
      //outline: none;
    }
  }

  .sl-counter {
    display: none;
    position: fixed;
    top: 30px;
    left: 30px;
    z-index: 10060;
    color: $sl-navigation-color;
    font-size: $sl-counter-fontsize;
  }

  .sl-download {
    display: none;
    position: fixed;
    bottom: 5px;
    width: 100%;
    text-align: center;
    z-index: 10060;
    color: $sl-caption-color;
    font-size: $sl-download-fontsize;

    a {
      color: $sl-caption-color;
    }
  }

  .sl-navigation {
    width: 100%;
    display: none;
    button {
      position: fixed;
      top: 50%;
      margin-top: -22px;
      height: 44px;
      width: 22px;
      line-height: 44px;
      text-align: center;
      display: block;
      z-index: 10060;
      font-family: $sl-font-family;
      color: $sl-navigation-color;
      &.sl-next {
        right: 5px;
        font-size: $sl-arrow-fontsize-small;
      }

      &.sl-prev {
        left: 5px;
        font-size: $sl-arrow-fontsize-small;
      }

      &:focus {
        //outline: none;
      }

      @media (min-width: $sl-breakpoint-medium) {
        width: 44px;

        &.sl-next {
          right: 10px;
          font-size: $sl-arrow-fontsize-medium;
        }

        &.sl-prev {
          left: 10px;
          font-size: $sl-arrow-fontsize-medium;
        }
      }
      @media (min-width: $sl-breakpoint-large) {
        width: 44px;

        &.sl-next {
          right: 20px;
          font-size: $sl-arrow-fontsize-large;
        }

        &.sl-prev {
          left: 20px;
          font-size: $sl-arrow-fontsize-large;
        }
      }
    }
  }

  &.sl-dir-rtl {
    .sl-navigation {
      direction: ltr;
    }
  }

  .sl-image {
    border-radius: 10px;
    overflow: hidden;
    position: fixed;
    @if $add-vendor-prefixes {
      -ms-touch-action: none;
    }
    touch-action: none;
    z-index: 10000;
    img {
      margin: 0;
      padding: 0;
      display: block;
      width: 100%;
      height: auto;
    }
    iframe {
      background: #000;
      border: $sl-iframe-border-small;
      @media (min-width: $sl-breakpoint-medium) {
        border: $sl-iframe-border-medium;
      }
      @media (min-width: $sl-breakpoint-large) {
        border: $sl-iframe-border-large;
      }
    }
    .sl-caption {
      display: none;
      padding: 10px;
      color: $sl-caption-color;
      background: $sl-caption-background;
      font-size: $sl-caption-fontsize;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      &.pos-top {
        bottom: auto;
        top: 0;
      }

      &.pos-outside {
        bottom: auto;
      }
    }
  }
}

.sl-spinner {
  display: none;
  border: 5px solid #333;
  border-radius: 40px;
  height: 40px;
  left: 50%;
  margin: -20px 0 0 -20px;
  opacity: 0;
  position: fixed;
  top: 50%;
  width: 40px;
  z-index: 1007;
  @if $add-vendor-prefixes {
    -webkit-animation: pulsate 1s ease-out infinite;
    -moz-animation: pulsate 1s ease-out infinite;
    -ms-animation: pulsate 1s ease-out infinite;
    -o-animation: pulsate 1s ease-out infinite;
  }
  animation: pulsate 1s ease-out infinite;
}

.sl-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.sl-transition {
  @if $add-vendor-prefixes {
    transition: -moz-transform ease 200ms;
    transition: -ms-transform ease 200ms;
    transition: -o-transform ease 200ms;
    transition: -webkit-transform ease 200ms;
  }
  transition: transform ease 200ms;
}

@-webkit-keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

@keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

@if $add-vendor-prefixes {
  @-moz-keyframes pulsate {
    0% {
      transform: scale(0.1);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(1.2);
      opacity: 0;
    }
  }

  @-o-keyframes pulsate {
    0% {
      transform: scale(0.1);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(1.2);
      opacity: 0;
    }
  }

  @-ms-keyframes pulsate {
    0% {
      transform: scale(0.1);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(1.2);
      opacity: 0;
    }
  }
}
