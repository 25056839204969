// Import your CSS/SCSS files here:
@font-face {
  font-family: "aperculight";
  src: url("fonts/apercu-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "apercubold";
  src: url("fonts/apercubold-webfont.woff2") format("woff2"),
    url("fonts/apercubold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
body {
  font-family: "Albert Sans", sans-serif;
  font-size: 19px;
  @apply text-text bg-white;
}
h1,
.h1 {
  font-size: responsive 30px 55px;
  line-height: responsive 34px 60px;
}
p,
span,
input {
  font-size: responsive 16px 19px;
  line-height: responsive 25px 30px;
}
.aperucu {
  font-family: "aperculight";
}
.aperucu-bold {
  font-family: "apercubold";
}

p {
  margin-bottom: 1.5rem;
  @media (max-with: 720px) {
    margin-bottom: 10px;
  }
}
.yellow-font {
  @media (max-width: 1500px) {
    font-size: responsive 50px 120px;
    line-height: responsive 55px 125px;
  }
  font-family: "apercubold";
  font-size: responsive 60px 147px;
  line-height: responsive 65px 150px;
  color: #ffdf43;
}
.outline-font {
  font-family: "apercubold";
  font-size: responsive 60px 149px;
  line-height: responsive 75px 160px;
  color: transparent;
  -webkit-text-stroke: 2px #ffdf43;
}
.outline-font.grey-font {
  -webkit-text-stroke: 0px #ffdf43;
  color: #e4e4e4;
}
.wrapper {
  width: 100%;
  margin: 0 auto;
  // max-width: calc(1180px + 40px);
  padding: 0 20px;
  @media (min-width: 800px) {
    padding: 0 60px;
  }
  @media (min-width: 1200px) {
    padding: 0 120px;
  }
}
h1,
h2,
h3 {
  font-family: "aperculight";
}
h3 {
  font-size: responsive 25px 35px;
  line-height: responsive 34px 40px;
  font-family: "aperculight";
}
.hero-title {
  font-size: responsive 25px 55px;
  line-height: responsive 30px 60px;
  font-weight: 200;
}
.bg-video {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
}
.bg-video {
  @apply absolute inset-0 overflow-hidden;
  // z-index: 1;
  & > div {
    // width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 1920px !important;
    height: 1080px !important;
    min-width: 1920px !important;
    min-height: 1080px !important;
  }
}
.fixed-nav {
  @media (min-width: 1024px) {
    transform: translateX(-143px);
  }
}
.let-spacing {
  letter-spacing: 2.2px;
}
.maintenance {
  left: -155px;
  width: 384px;
  height: 74px;
  text-align: center;
  padding-top: 22px;
  top: 155px;
}
.fitting {
  left: -83px;
  width: 384px;
  height: 68px;
  text-align: center;
  padding-top: 22px;
  top: 158px;
}

.bike-img {
  @media (min-width: 1024px) {
    position: absolute;
    left: 0;
    width: 113%;
    max-width: 120%;
    left: -15%;
    bottom: 0;
  }
  max-width: 400px;
  width: 100%;
}
.text-ul {
  ul {
    padding-left: 15px;
    li {
      margin-bottom: 5px;
      list-style: initial;
    }
  }
}
.large-text {
  h2 {
    font-family: "aperculight";
    font-size: responsive 22px 55px;
    line-height: responsive 31px 75px;
  }
}
h2 {
  font-family: "aperculight";
  font-size: responsive 22px 55px;
  line-height: responsive 31px 75px;
}
.pattern-bg {
  background-size: 100%;
}

.image-gallery-wall {
  background: white;
}
section.image-gallery-wall {
  position: relative;
  height: 100vh;
  will-change: transform;
  overflow: hidden;
}
section.image-gallery-wall .image-gallery {
  position: absolute;
  top: -100%;
  left: -100%;
  width: 300%;
  height: 300%;
}
section.image-gallery-wall .image {
  position: absolute;
}
section.image-gallery-wall .image img {
  height: calc(100% - 120px);
  width: calc(100% - 120px);
  margin-top: 60px;
  margin-left: 60px;
  object-fit: cover;
  display: block;
}
section.image-gallery-wall .image#image-gallery-image-1 {
  width: 33.333%;
  height: 33.333%;
  top: 33.3333%;
  left: 33.3333%;
  @media (max-width: 600px) {
    width: 63.333%;
    height: 33.333%;
    top: 33.3333%;
    left: 20.3333%;
  }
}
section.image-gallery-wall .image#image-gallery-image-2 {
  width: 23.666%;
  height: 33.333%;
  top: 50%;
  left: 9.666%;
  @media (max-width: 600px) {
    display: none;
  }
}
section.image-gallery-wall .image#image-gallery-image-3 {
  width: 24.666%;
  height: 23.333%;
  top: 32.666%;
  left: 66.6666%;
  @media (max-width: 600px) {
    width: 36.666%;
    height: 20.333%;
    top: 12.666%;
    left: 46.6666%;
  }
}
section.image-gallery-wall .image#image-gallery-image-4 {
  width: 24%;
  height: 17%;
  top: 33.2%;
  left: 9.6%;
  @media (max-width: 600px) {
    width: 42%;
    height: 20%;
    top: 15%;
    left: 8.333%;
  }
}
section.image-gallery-wall .image#image-gallery-image-5 {
  width: 16.6666%;
  height: 16.6%;
  top: 66.666%;
  left: 33.3333%;
  @media (max-width: 600px) {
    width: 46.6666%;
    height: 33.3333%;
    top: 65.666%;
    left: 43.3333%;
  }
}
section.image-gallery-wall .image#image-gallery-image-6 {
  width: 16.6666%;
  height: 16.6666%;
  top: 66.6666%;
  left: 50%;
  @media (max-width: 600px) {
    top: 66.666%;
    left: 5.3333%;
    width: 36.6666%;
    height: 26.6666%;
  }
}
section.image-gallery-wall .image#image-gallery-image-7 {
  width: 24.666%;
  height: 27.333%;
  top: 56%;
  left: 66.666%;
  @media (max-width: 600px) {
    display: none;
  }
}
// .mega-menu {
//   opacity: 0;
//   visibility: hidden;
// }
.menu-parent {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  @media (max-width: 800px) {
    display: block;
    padding-top: 100px;
  }
}

.mega-menu {
  max-width: 1680px;
  margin: 0 auto;
  left: 50% !important;
  transform: translateX(-50%);

  div {
    position: relative;
    @media (max-width: 800px) {
      height: 10vh;
    }
  }
}
@media (max-width: 1024px) {
  .mob-text {
    fill: black !important;
  }
}
.head-one {
  .hero-title {
    opacity: 0.5;
  }
  p {
    opacity: 0;
  }
}
.head-one:hover {
  .hero-title {
    opacity: 1;
    color: #ffdf43 !important;
  }
  p {
    opacity: 1;
  }
}
.head-two {
  .hero-title {
    opacity: 0.5;
  }
  p {
    opacity: 0;
  }
}
.head-two:hover {
  .hero-title {
    opacity: 1;
    color: #ffdf43 !important;
  }
  p {
    opacity: 1;
  }
}
.head-one.active {
  .hero-title {
    opacity: 1;
  }
  p {
    opacity: 1;
  }
}
.head-two.active {
  .hero-title {
    opacity: 1;
  }
  p {
    opacity: 1;
  }
}
.div1 {
  grid-area: 1 / 1 / 2 / 2;
  @media (min-width: 1024px) {
    border-right: 1px solid white;
    border-bottom: 1px solid #fff;
  }
  background-image: url("images/nav/retul-5.jpg");
}

.div2 {
  grid-area: 1 / 2 / 2 / 3;
  @media (min-width: 1024px) {
    border-right: 1px solid white;
    border-bottom: 1px solid #fff;
  }
}

.div3 {
  grid-area: 1 / 3 / 2 / 4;
  @media (min-width: 1024px) {
    border-right: 1px solid white;
    border-bottom: 1px solid #fff;
  }
}

.div4 {
  grid-area: 1 / 4 / 2 / 5;
  @media (min-width: 1024px) {
    border-right: 1px solid white;
  }
}

.div5 {
  grid-area: 2 / 1 / 3 / 2; /* Adjust the grid area for the fifth div */
  @media (min-width: 1024px) {
    border-right: 1px solid white;
  }
}
.div6 {
  grid-area: 2 / 2 / 3 / 3; /* Adjust the grid area for the fifth div */
  @media (min-width: 1024px) {
    border-right: 1px solid white;
  }
}
.div7 {
  grid-area: 2 / 3 / 3 / 4; /* Adjust the grid area for the fifth div */
  @media (min-width: 1024px) {
    border-right: 1px solid white;
  }
}
.div8 {
  grid-area: 2 / 4 / 3 / 5; /* Adjust the grid area for the fifth div */
  @media (min-width: 1024px) {
    border-right: 1px solid white;
  }
}

.two-text {
  ul {
    list-style: inherit;
    margin-left: 1rem;
  }
}
.news-wrapper {
  margin: 0 auto;
  max-width: calc(1160px + 20vw);
  padding: 0 10vw;
  width: 100%;
}
.news-block h3 {
  margin-bottom: 2rem;
}
.h3 {
  font-size: 22px;
  font-weight: 500;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
  text-align: center;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 90vh;
}
.videoWrapper video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
